@keyframes marquee {
  0%{ transform: translateX(100%);}
  100%{transform: translateX(-20%);}
}

@keyframes marquee1 {
  0%{ transform: translateX(100%); }
  100%{ transform: translateX(-30%); }
}

.movingText:hover {
  animation-play-state: paused;
}

.movingText1:hover {
  animation-play-state: paused;
}

.movingText,
.movingText1 {
  font-size: 2vw;
  font-weight: bold;
  color: var(--font-color);
  /* font-family: sweetnote; */
  letter-spacing: 3px;
}

.glass {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(161, 140, 94, 0.37);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border: 1px solid rgba(161, 140, 94, 0.3);
}

@media (prefers-reduced-motion: no-preference) {
  .movingText {
    animation: marquee 20s linear infinite;
  }

  .movingText1 {
    animation: marquee1 23s linear infinite;
  }
}

@media (min-width: 720px) {
  .movingText,
  .movingText1 {
    font-size: 2vw;
  }
}

@media (min-width: 900px) {
  .movingText,
  .movingText1 {
    font-size: 1.5vw;
  }
}

@media (min-width: 1200px) {
  .movingText,
  .movingText1 {
    font-size: 1vw;
  }
}