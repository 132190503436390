.submitButton {
  border-radius: 25px;
  margin-top: 2vh;
  letter-spacing: 2px;
  color: var(--color-secondary-2-1);
  background-color: var(--color-primary-3);
  font-size: 3vw;
}

@media (min-width: 720px) {
  .submitButton {
    font-size: 1.5vw;
  }
}

@media (min-width: 1200px) {
  .submitButton {
    font-size: 1vw;
  }
}